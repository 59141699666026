// Styles SCSS
import "../sass/app.scss";

// jQuery
import $ from "jquery";
window.jQuery = $;
window.$ = $;

// Masonry layout
import Masonry from "./utils/masonry";

// SVG polyfiil
import "svgxuse";

// Flash Info
import "jquery.marquee";

import datepicker from "js-datepicker";

// Accordions
import Accordion from "accordion-js";

//Tabs
import { Tabs } from "./utils/tabs";

// pushbar
import Pushbar from "./utils/pushbar";

// Lightbox
import "./utils/fancybox";

// LazyLoad
import lazyLoadInit from "./lazyload-init";

lazyLoadInit();

$(document).ready(() => {
  // Toggle unclic.twig + change le chevron
  $("#demarches").click(function () {
    $("#unclic").toggle();
    $(this).toggleClass("rotate");
  });

  if (document.querySelector(".accordion-container") !== null) {
    new Accordion(".accordion-container");
  }
  if (document.querySelector(".tabs-container") !== null) {
    new Tabs({
      elem: "tabs",
      open: 0,
    });
  }

  $("#submit-filter-annuaire").on("click", function (e) {
    e.preventDefault;

    var filter = $("#filter-annuaires");
    var loading = $(".load-bar");
    $.ajax({
      url: filter.attr("action"),
      data: filter.serialize(),
      type: "POST",
      action: "ajax_function_annuaire",
      beforeSend: function () {
        $(".annuaires").html("");
        loading.toggleClass("invisible");
      },
      success: function (data) {
        loading.toggleClass("invisible");
        $(".annuaires").html(data);
      },
    });
    return false;
  });

  new Pushbar({
    blur: true,
    overlay: true,
  });

  jQuery(".responsive-menu__list .svgOpen").on("click", function () {
    console.log(this);
    jQuery(this).toggleClass("rotatesvg");
    jQuery(this).parent().siblings(".responsive-menu__sousmenu").slideToggle();
  });

  $('[data-fancybox="galerie"], .gallery-item a').fancybox({
    thumbs: {
      autoStart: true,
    },
  });
  $('[data-fancybox="galerie-videos"], .gallery-item a').fancybox({
    thumbs: {
      autoStart: true,
    },
    youtube: {
      controls: 0,
      showinfo: 0,
    },
    vimeo: {
      color: "f00",
    },
  });

  if (document.querySelector(".galerie") !== null) {
    const grid = document.querySelector(".galerie");
    new Masonry(grid, {
      itemSelector: ".galerie__item",
      columnWidth: 250,
      gutter: 10,
    });
  }
  if (document.querySelector(".galerie-video") !== null) {
    const grid_video = document.querySelector(".galerie-video");
    new Masonry(grid_video, {
      itemSelector: ".galerie__item",
      columnWidth: 250,
      gutter: 10,
    });
  }

  // back to top button
  if ($("#back-to-top").length) {
    var scrollTrigger = 100, // px
      backToTop = function () {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > scrollTrigger) {
          $("#back-to-top").addClass("show");
        } else {
          $("#back-to-top").removeClass("show");
        }
      };
    backToTop();
    $(window).on("scroll", function () {
      backToTop();
    });
    $("#back-to-top").on("click", function (e) {
      e.preventDefault();
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        700
      );
    });
  }

  jQuery(".header-search__icon").on("click", function () {
    jQuery(this).addClass("active");
    jQuery(this).find("input").focus();
  });
});
